import {Reducer} from "react";

export interface AuthorizationState {
    isLoggedIn: boolean;
    loading: boolean;
}

export enum AuthorizationActionTypes {
    SET_IS_LOGGED_IN,
    SET_IS_LOADING
}

interface SetIsLoggedIn {
    type: AuthorizationActionTypes.SET_IS_LOGGED_IN,
    value: boolean;
}

interface SetIsLoading {
    type: AuthorizationActionTypes.SET_IS_LOADING,
    value: boolean;
}

export type AuthorizationAction = SetIsLoggedIn | SetIsLoading;

export const AuthorizationReducer: Reducer<AuthorizationState, AuthorizationAction> = (state, action): AuthorizationState => {

    switch (action.type) {
        case AuthorizationActionTypes.SET_IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.value
            }
        case AuthorizationActionTypes.SET_IS_LOADING:
            return {
                ...state,
                loading: action.value
            }
        default:
            return {
                ...state
            }
    }

}