import React from "react";
import {AuthorizationAction, AuthorizationActionTypes} from "../context/authorization/athorization-reducer";

class AuthorisationService {

    private authDispatcher: React.Dispatch<AuthorizationAction> | null

    constructor() {
        this.authDispatcher = null
    }

    setAuthDispatcher(authDispatcher: React.Dispatch<AuthorizationAction>) {
        this.authDispatcher = authDispatcher;
    }

    async isLoggedIn() {
        let response = await fetch("/api/v1/public/is-logged-in")

        if (this.authDispatcher !== null ) {
            this.authDispatcher({type: AuthorizationActionTypes.SET_IS_LOADING, value: false})
        }

        if (response.status === 200) {
            let respJson = await response.json()
            console.log(respJson)

            if (respJson.username && respJson.username.length > 0) {
                if (this.authDispatcher !== null ) {
                    this.authDispatcher({type: AuthorizationActionTypes.SET_IS_LOGGED_IN, value: true})
                }
            }

        } else {
            if (this.authDispatcher !== null ) {
                this.authDispatcher({type: AuthorizationActionTypes.SET_IS_LOGGED_IN, value: false})
            }
            return false
        }

    }

    async login(username: string, password: string): Promise<any> {
        let response = await fetch("/api/v1/public/login", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({ username: username, password: password }),
        })

        if (response.status === 200) {
            let respJson = await response.json()

            setTimeout(() => {console.log(respJson)}, 2000)

            window.location.href = "/property";
        } else {
            return false
        }
    }

}


let authService = new AuthorisationService()
export default authService;