import './application-skeleton.scss'
import {ReactElement, ReactNode, useState} from "react";
import {NavLink, Route, Routes} from "react-router-dom";
import {Test} from "../test/test";
import {Avatar} from "antd";
import {PropertyList} from "../property/property-list";
import {Property} from "../property/property";
import {MembersList} from "../members/members-list";
import {Icon} from "../icons/icon";

export const ApplicationSkeleton = ()  => {

    const [openMobileMenu, setOpenMobileMenu] = useState(false);

    return (
        <>
            <div className={"app-overlay-menu-container " + (openMobileMenu ? "open": "")}>
                <div className={"menu-items-container"}>
                    <NavLink key={2} to={"/property"} className={"button"} onClick={() => {setOpenMobileMenu(!openMobileMenu)}}>{"Property"}</NavLink>
                    <NavLink key={3} to={"/members"} className={"button"} onClick={() => {setOpenMobileMenu(!openMobileMenu)}}>{"Members"}</NavLink>
                    <NavLink key={3} to={"/users"} className={"button"} onClick={() => {setOpenMobileMenu(!openMobileMenu)}}>{"Users"}</NavLink>
                </div>
            </div>
            <div className={"app-container"}>
                <div className={"menu"}>
                    <div className={"menu-button"}>
                        <button onClick={() => {setOpenMobileMenu(!openMobileMenu)}}>{openMobileMenu ? <Icon iconName={"Close"}/> : <Icon iconName={"Menu"}/>}</button>
                    </div>
                    <div className={"logo-container"}>
                        <NavLink key={0} to={"/"}>
                            <img src={"/kodus_beta_logo.svg"} alt="kodus_logo" />
                        </NavLink>
                    </div>
                    <div className={"menu-items-container"}>
                        <NavLink key={2} to={"/property"} className={"button"}>{"Property"}</NavLink>
                        <NavLink key={3} to={"/members"} className={"button"}>{"Members"}</NavLink>
                        <NavLink key={3} to={"/users"} className={"button"}>{"Users"}</NavLink>
                    </div>
                    <div className={"account-button-container"}>
                        <Avatar size={30}>SR</Avatar>
                    </div>
                </div>
                <div className={"content"}>
                    <Routes>
                        <Route key={0} index={true} path={"/users"} element={<Test name={"users"}/>}/>
                        <Route key={0} index={true} path={"/property"} element={<PropertyList/>}/>
                        <Route key={0} index={true} path={"/property/:id"} element={<Property/>}/>
                        <Route key={0} index={true} path={"/members"} element={<MembersList/>}/>
                    </Routes>
                </div>
            </div>
        </>
    );
}