import {OwnersTableDropDownComponentProps} from "../../../models/owner/owner";
import React from "react";
import {Dropdown, MenuProps, Popconfirm, Space} from "antd";
import {Icon} from "../../icons/icon";

export const OwnersTableDropDown = (props: OwnersTableDropDownComponentProps) => {

    const items: MenuProps['items'] = [
        {
            label: <Space style={{width: "100%"}} onClick={() => {props.openEditModal(props.owner.owner, props.propertyId)}}>Редактировать</Space>,
            key: '0',
        },
        {
            label: ( props.owner.isMember
                ? <Popconfirm
                    title="Удалить членство"
                    description="Вы уверены в этом действии?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => {props.clearAssignOwnerAsMember({ownerId: props.owner.ownerId, propertyId: props.propertyId})}}
                >
                    <Space style={{width: "100%"}}>Удалить членство</Space>
                </Popconfirm>
                : <Popconfirm
                    title="Сделать членом"
                    description="Вы уверены в этом действии?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => {props.assignOwnerAsMember({ownerId: props.owner.ownerId, propertyId: props.propertyId})}}
                >
                    <Space style={{width: "100%"}}>Сделать членом</Space>
                </Popconfirm>),
            key: '1',
        },
        {
            label: <Popconfirm
                    title="Отвязать от собственности"
                    description="Вы уверены в этом действии?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => {props.unlinkOwnerAssignFromProperty({ownerId: props.owner.ownerId, propertyId: props.propertyId})}}
                >
                    <Space style={{width: "100%"}}>Отвязать от собственности</Space>
                </Popconfirm>,
            key: '2',
        },
    ];

    return(
        <>
            <Dropdown menu={{items}} trigger={['click']}>
                <button>
                    <Icon iconName={"More"}/>
                </button>
            </Dropdown>
        </>
    );
}