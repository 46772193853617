import {Route} from "react-router-dom";
import React from "react";
interface props {
    name: string
}
export const Test: React.FC<props> = ({name}) => {

    return (
        <p>{name}</p>
    );
}